.imageLoader {
    margin: 0 auto;
    position: relative;
  }
  
  .imageLoader img {
    height: 100%;
  }
  
  .imageLoader .realImg {
    position: absolute;
    top: 0;
    left: 0;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }