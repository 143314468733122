.container {
  width: 100%;
  height: 100%;
}

.main-screen-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -50;
}

.mobile-main-page-body {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

.zone-button {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 42%;
  top: 15%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.guild-hall-button {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 62%;
  bottom: 70%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.guild-hire-button {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 23%;
  bottom: 47%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.arena-button {
  position: absolute;
  width: 10%;
  height: 10%;
  right: 24%;
  bottom: 48%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.market-button {
  position: absolute;
  left: 32%;
  bottom: 32%;
  border: none;
  width: 10%;
  height: 10%;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.flame-effect {
  position: absolute;
  left: 23%;
  bottom: 75%;
  border: none;
  color: white;
  height: 5rem;
}

.crafting-button {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 24%;
  bottom: 62%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.upgrade-button {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 43%;
  bottom: 34%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.fontain-button {
  width: 10%;
  height: 10%;
  position: absolute;
  left: 47%;
  top: 32%;
  border: none;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.buttons {
  display: flex;
  position: absolute;
  right: 5%;
  outline: none;
  column-gap: 10%;
}

.buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 3rem;
  height: 2rem;
  background: linear-gradient(180deg,
      rgba(226, 224, 224, 0.04) -26.6%,
      rgba(218, 218, 218, 0.19) 132.98%),
    linear-gradient(180deg,
      rgba(226, 224, 224, 0.04) -26.6%,
      rgba(218, 218, 218, 0.19) 132.98%);
  backdrop-filter: blur(197px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px;
  outline: none;
  border: 1px solid #FFFFFF49;
}

.craft-hover-img {
  position: absolute;
  left: 17.2%;
  top: 11.8%;
  cursor: pointer;
  width: 23%;
  z-index: -1;
}

.mint-hero-hover-img {
  position: absolute;
  left: 17.85%;
  bottom: 33.45%;
  width: 20%;
  cursor: pointer;
  z-index: -1;
}

.upgrade-hover-img {
  position: absolute;
  left: 42.9%;
  bottom: 35.4%;
  width: 10%;
  cursor: pointer;
  z-index: -1;
}

.hideout-button {
  font-family: 'Oxanium', cursive;
  border-radius: 25px;
  background: #00000085;
  padding: 6px;
}

.swal2-confirm {
  font-family: 'Oxanium', cursive;
}

.market-hover-img {
  position: absolute;
  left: 25.9%;
  bottom: 21%;
  width: 20%;
  cursor: pointer;
  z-index: -1;
}

.zone-hover-img {
  position: absolute;
  left: 37.74%;
  top: 0.4%;
  width: 16.5%;
  cursor: pointer;
  z-index: -1;
}

.arena-hover-img {
  position: absolute;
  left: 59.6%;
  bottom: 39%;
  width: 24%;
  cursor: pointer;
  z-index: -1;
}

.castle-hover-img {
  position: absolute;
  left: 57%;
  bottom: 58.5%;
  width: 25%;
  cursor: pointer;
  z-index: -1;
}

.fontain-hover-img {
  position: absolute;
  left: 42.6%;
  bottom: 49.7%;
  width: 17%;
  cursor: pointer;
  z-index: -1;
}